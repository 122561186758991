import React from "react";
import styled from "@emotion/styled";
import { Star, StarBorder } from "@mui/icons-material";
import PropTypes from "prop-types";

import Paper from "../Paper/Paper";
import { imageParser } from "../../utils/image.utils";

const TestimonialCardWrapper = styled.div`
  width: 303px;
  border-radius: 16px;
  border: 2px solid #003032;
  background: #fff;
  box-shadow: 14px 14px 0px 0px #003032;
  margin-right: 16px;

  .testimonial {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px;
    background-color: var(--kenkoPureWhite);
  }

  .testimonial .header {
    display: flex;
    justify-content: space-between;
    padding: 25px 25px 0 25px;
  }

  .testimonial .header-quotes {
    display: flex;
    gap: 5px;
  }

  .testimonial .body {
    margin-bottom: auto;
    padding: 25px;
  }

  .testimonial .footer {
    padding: 15px 25px;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: auto;
  }

  .testimonial .footer .avatar {
    border-radius: 50%;
  }

  .testimonial .footer .rating {
    color: var(--yellow500);
  }

  @media (max-width: 600px) {
    .testimonial .body {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const TwitterProfileText = styled.p`
  color: #737373;
  font-family: Figtree;
  font-size: 16px;
  font-weight: 500;
  margin: 0;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const NameText = styled.p`
  color: #1a1a1a;
  font-family: Figtree;
  font-size: 18px;
  font-weight: 700;
  margin: 0;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;
export default function TestimonialCard({
  key,
  feedback,
  name,
  rating,
  twitterProfile,
}: any) {
  return (
    <TestimonialCardWrapper key={key}>
      <div className="testimonial">
        <div className="header">
          <img
            src={imageParser(
              `${rating ? "logos/twitter_logo.svg" : "logos/google_logo.svg"}`
            )}
            alt="Twitter"
          />
        </div>

        <div className="body">{feedback}</div>

        <div className="footer">
          <div className="details">
            <NameText>{name}</NameText>
            <TwitterProfileText>{twitterProfile}</TwitterProfileText>

            {rating && (
              <div className="rating">
                {Array(rating)
                  .fill(null)
                  .map((key: any, index: number) => (
                    <Star fontSize="inherit" key={key + index} />
                  ))}
                {Array(5 - rating).fill(<StarBorder />)}
              </div>
            )}
          </div>
        </div>
      </div>
    </TestimonialCardWrapper>
  );
}

TestimonialCard.propTypes = {
  /**
   * The feedback given by the user
   */
  feedback: PropTypes.element.isRequired,
  /**
   * The name of the user
   */
  name: PropTypes.string.isRequired,
  /**
   * The image path of the user
   */
  avatarImg: PropTypes.string,
  /**
   * The rating given by the user
   */
  rating: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
};
