import { memo, useEffect } from "react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
// import { useViewport } from "../../../shared/hooks";

import TestimonialCard from "../../../shared/components/TestimonialCard/TestimonialCard";
import Typography from "../../../shared/components/Typography/Typography";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { backendDrivenEvents } from "../../../slices/onboarding.slice";
import { backendEvents } from "../../../shared/constants/backend-events";
// import CarouselBar from "../../../shared/icons/CarouselBar";
// import { imageParser } from "../../../shared/utils/image.utils";

// const SliderWrapper = styled(Slider)({
//   height: "auto",
//   "& .slick-slide": {
//     opacity: 0.4,
//   },
//   "& .slick-track": {
//     // paddingLeft: "16px",
//   },
//   "& .slick-slide.slick-center": {
//     opacity: 1,
//   },
//   "& .slick-slide.slick-center + .slick-slide.slick-active": {
//     opacity: 1,
//   },
//   "& .slick-slide:has(+ .slick-center), .slick-center:hover, .slick-center": {
//     opacity: 1,
//   },
//   "& .slick-arrow.slick-prev": {
//     background: " #F4F7ED",
//     height: "fit-content",
//     borderRadius: "50%",
//     position: "absolute",
//     zIndex: "100",

//     border: "1px solid #E3EBD2",
//   },
//   "& .slick-arrow.slick-next": {
//     background: " #F4F7ED",
//     height: "fit-content",
//     borderRadius: "50%",
//     position: "absolute",
//     zIndex: "100",
//     marginRight: "1%",
//     border: "1px solid #E3EBD2",
//   },
//   "& .slick-disabled": {
//     opacity: 0.5,
//     cursor: "auto",
//   },
//   "& .slick-dots li.slick-active a svg path": {
//     fillOpacity: 1,
//   },
// });

const TestimonialWrapper = styled(Grid)`
  padding: 48px 0;
  width: 100%;
  background-color: #f7cc5f;
`;

const TestimonialTitle = styled.p`
  color: #243242;
  text-align: center;
  font-family: Figtree;
  font-size: 42px;
  font-weight: 600;
  margin: 0 auto;
  line-height: 48px; /* 114.286% */

  @media (max-width: 600px) {
    font-size: 24px;
    width: 70%;
    line-height: normal;
  }
`;

const SliderContainer = styled.div`
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  padding: 60px 0;
  position: relative;

  @keyframes slide {
    from {
      transform: translate(0);
    }
    to {
      transform: translate(calc(-50% - 0.5rem));
    }
  }

  .logos-slide {
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    padding: 0;
    width: fit-content;
    animation: slide 50s linear infinite;
  }
`;

export const TestimonialSection = () => {
  // const matchesXS = useViewport(600).below;

  // const testimonialSliderSettings = {
  //   ...sliderSettings,
  //   nextArrow: <></>,
  //   prevArrow: <></>,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   speed: 1000,
  //   infinite: true,
  //   dots: false,
  //   centerMode: true,
  //   centerPadding: "0 0",
  //   initialSlide: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1550,
  //       settings: {
  //         slidesToShow: 4.75,
  //         centerPadding: "14vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 1500,
  //       settings: {
  //         slidesToShow: 4.5,
  //         centerPadding: "8vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 1430,
  //       settings: {
  //         slidesToShow: 4.25,
  //       },
  //     },
  //     {
  //       breakpoint: 1330,
  //       settings: {
  //         slidesToShow: 4.15,
  //       },
  //     },
  //     {
  //       breakpoint: 1280,
  //       settings: {
  //         slidesToShow: 3.65,
  //         centerPadding: "28vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: 3.45,
  //         centerPadding: "22vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 1120,
  //       settings: {
  //         slidesToShow: 3.25,
  //         centerPadding: "14vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 1040,
  //       settings: {
  //         slidesToShow: 3.1,
  //         centerPadding: "7vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 1000,
  //       settings: {
  //         slidesToShow: 2.75,
  //         centerPadding: "26vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 930,
  //       settings: {
  //         slidesToShow: 2.5,
  //         centerPadding: "13vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 830,
  //       settings: {
  //         slidesToShow: 2.25,
  //         centerPadding: "0vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 750,
  //       settings: {
  //         slidesToShow: 2.1,
  //       },
  //     },
  //     {
  //       breakpoint: 680,
  //       settings: {
  //         slidesToShow: 1.8,
  //         centerPadding: "38vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 650,
  //       settings: {
  //         slidesToShow: 1.7,
  //         centerPadding: "35vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 615,
  //       settings: {
  //         slidesToShow: 1.6,
  //         centerPadding: "33vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 599,
  //       settings: {
  //         slidesToShow: 1.7,
  //         centerPadding: "35vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 1.65,
  //         centerPadding: "33vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 560,
  //       settings: {
  //         slidesToShow: 1.6,
  //         centerPadding: "31vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 560,
  //       settings: {
  //         slidesToShow: 1.6,
  //         centerPadding: "31vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 540,
  //       settings: {
  //         slidesToShow: 1.5,
  //         centerPadding: "28vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 510,
  //       settings: {
  //         slidesToShow: 1.45,
  //         centerPadding: "26vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 490,
  //       settings: {
  //         slidesToShow: 1.4,
  //         centerPadding: "24vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 470,
  //       settings: {
  //         slidesToShow: 1.35,
  //         centerPadding: "23vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 455,
  //       settings: {
  //         slidesToShow: 1.3,
  //         centerPadding: "21vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 435,
  //       settings: {
  //         slidesToShow: 1.25,
  //         centerPadding: "17vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 415,
  //       settings: {
  //         slidesToShow: 1.2,
  //         centerPadding: "15vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 400,
  //       settings: {
  //         slidesToShow: 1.15,
  //         centerPadding: "12vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 380,
  //       settings: {
  //         slidesToShow: 1.12,
  //         centerPadding: "8vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 360,
  //       settings: {
  //         slidesToShow: 1.1,
  //         centerPadding: "8vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 350,
  //       settings: {
  //         slidesToShow: 1.07,
  //         centerPadding: "7vw 0",
  //       },
  //     },
  //     {
  //       breakpoint: 340,
  //       settings: {
  //         slidesToShow: 1.05,
  //         centerPadding: "5vw 0",
  //       },
  //     },
  //   ],
  //   slidesToShow: 5,
  //   customPaging: function () {
  //     return (
  //       <a>
  //         <CarouselBar background={"#71A4A5"} />
  //       </a>
  //     );
  //   },
  // };

  // Update testimonialSchema items in helpers
  const testimonialItems = [
    {
      id: 1,
      feedback: (
        <Typography variant="body3">
          Happy to receive{" "}
          <span style={{ fontWeight: "650" }}>constant support</span> from The
          Kenko Team during an emergency. I'm glad to get reimbursed without any
          stress.
        </Typography>
      ),
      name: "Nilesh Kanherkar",
      rating: 5,
    },
    {
      id: 2,
      feedback: (
        <Typography variant="body3">
          Very straightforward operations and easy app to use. Probably the
          first time my LFT tests happened this smoothly. Keep it up!
        </Typography>
      ),
      name: "Madhu K",
      twitterProfile: "@madhuK109",
    },
    {
      id: 3,
      feedback: (
        <Typography variant="body3">
          Love this new concept in the health industry. Must for all Indians,
          who struggle to arrange security deposit during emergency
          hospitalisations.
        </Typography>
      ),
      name: "Nitesh Singh",
      rating: 5,
    },
    {
      id: 4,
      feedback: (
        <Typography variant="body3">
          This is regarding me and my kid’s doctor consultation. Received my
          kid’s reimbursement instantly, and mine came next morning!
        </Typography>
      ),
      name: "Ankit Solanki",
      twitterProfile: "@solankith",
    },
    {
      id: 5,
      feedback: (
        <Typography variant="body3">
          Thank you for your prompt service, Kenko! All my reimbursements were
          processed in 2 days!
        </Typography>
      ),
      name: "Mihi Vats",
      twitterProfile: "@VM1082",
    },
    {
      id: 6,
      feedback: (
        <Typography variant="body3">
          The customer delight team at Kenko was{" "}
          <span style={{ fontWeight: "650" }}>really helpful</span> during the
          entire treatment process for my mother. Appreciate their thorough
          professionalism.
        </Typography>
      ),
      name: "Shivam Singhania",
      avatarImg: "testimonial_section/Shivam Singhania.webp",
      avatarImgLoading: "testimonial_section/Shivam Singhania small.webp",
      rating: 5,
    },
    {
      id: 7,
      feedback: (
        <Typography variant="body3">
          Special shoutout to Team Kenko. My mother was hospitalised for minor
          treatment and Kenko{" "}
          <span style={{ fontWeight: "650" }}>
            transferred my benefits immediately
          </span>
          . I immensely appreciate their efforts.
        </Typography>
      ),
      name: "Shreekanth Sampigethaya",
      avatarImg: "testimonial_section/Shreekanth Sampigethaya.webp",
      avatarImgLoading:
        "testimonial_section/Shreekanth Sampigethaya small.webp",
      rating: 5,
    },
    {
      id: 8,
      feedback: (
        <Typography variant="body3">
          Kenko is the{" "}
          <span style={{ fontWeight: "650" }}>Best Family Health Plan</span> I
          have come across. They helped me throughout my illness. Their quick
          response was commendable.
        </Typography>
      ),
      name: "Vitthal Sitaram",
      avatarImg: "testimonial_section/Vitthal Sitaram.webp",
      avatarImgLoading: "testimonial_section/Vitthal Sitaram small.webp",
      rating: 5,
    },
    {
      id: 9,
      feedback: (
        <Typography variant="body3">
          Kenko reps were{" "}
          <span style={{ fontWeight: "650" }}>
            very helpful and cooperative
          </span>{" "}
          in assisting me with my subscription. Overall an excellent experience.
        </Typography>
      ),
      name: "Ajit More",
      avatarImg: "testimonial_section/Ajit More.webp",
      avatarImgLoading: "testimonial_section/Ajit More small.webp",
      rating: 5,
    },
    {
      id: 10,
      feedback: (
        <Typography variant="body3">
          I am delighted with Kenko's service. They provided me with{" "}
          <span style={{ fontWeight: "650" }}>
            a perfect blend of compassion and competence.
          </span>
        </Typography>
      ),
      name: "Naveen Prasad Dutta",
      avatarImg: "testimonial_section/Naveen Prasad Dutta.webp",
      avatarImgLoading: "testimonial_section/Naveen Prasad Dutta small.webp",
      rating: 5,
    },
    {
      id: 11,
      feedback: (
        <Typography variant="body3">
          I had all my benefits processed within two days. I would like to thank
          Kenko for their{" "}
          <span style={{ fontWeight: "650" }}>
            prompt service and quick thinking
          </span>{" "}
          of their customer delight team.
        </Typography>
      ),
      name: "Anitha Chaduvula",
      avatarImg: "testimonial_section/Anitha Chaduvula.webp",
      avatarImgLoading: "testimonial_section/Anitha Chaduvula small.webp",
      rating: 5,
    },
    {
      id: 12,
      feedback: (
        <Typography variant="body3">
          Exceptional experience with Kenko's team. They are{" "}
          <span style={{ fontWeight: "650" }}>polite and cooperative</span>. Got
          100% reimbursement for my mom's treatment from Kenko without any
          hassle.
        </Typography>
      ),
      name: "Abdul Sayyed",
      avatarImg: "testimonial_section/Abdul Sayyed.webp",
      avatarImgLoading: "testimonial_section/Abdul Sayyed small.webp",
      rating: 5,
    },
  ];
  const { ref, inView } = useInView();
  const dispatch = useDispatch();

  useEffect(() => {
    if (inView) {
      dispatch(
        backendDrivenEvents({
          ...backendEvents.HOMEPAGE_TESTIMONIAL_SECTION,
        })
      );
    }
  }, [inView, dispatch]);

  return (
    <TestimonialWrapper container ref={ref}>
      <TestimonialTitle>We'd love to have your story here</TestimonialTitle>
      <SliderContainer>
        <div className="logos-slide">
          {testimonialItems.map((item) => (
            <TestimonialCard {...item} key={item.id} />
          ))}
          {testimonialItems.map((item) => (
            <TestimonialCard {...item} key={item.id} />
          ))}
        </div>
      </SliderContainer>
    </TestimonialWrapper>
  );
};

export default memo(TestimonialSection);
